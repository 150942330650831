<template>
    <transition appear enter-active-class="animated fadeIn">
    <div>
        <div class="level-item">
            <h1 class="title is-1 page-title" >Travel Insurance for Spain</h1>
        </div>
        <div class="box b-shdw-3">
            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
            <div class="content">
         <p class="paragraph-text">
                    If you’re planning a holiday to Spain, it’s important to arrange your travel insurance before you go, so you can rest and relax with complete peace of mind, knowing your trip is covered. 
                </p>
                <p class="blue-text">Looking for travel insurance?</p>
                <p class="paragraph-text">
                    One of the most popular destinations for Brits, Spain is the ideal choice for a holiday. With its stunning Mediterranean coastline, rich history, and culture, and it’s only a short flight away. 
                </p>
                <p class="paragraph-text">
                    Spain has something for everyone, whether you want to visit majestic castles, explore the bustling city of Barcelona, or simply enjoying the sunshine and warmer weather Spain has to offer – just ensure your travel insurance is arranged as soon as you book, so you can rest assured your trip is covered. 
                </p>
                <p class="blue-text">Do I need travel insurance for Spain?</p>
                <p class="paragraph-text">
                    Yes, if you’re planning a holiday to Spain, you should always arrange travel insurance. 
                </p>
                <p class="paragraph-text">
                    It is ideal to arrange cover as soon as you book your trip, to ensure you are covered for cancellation. That means if you needed to cancel for reasons out of your control, you can recoup the costs of your holiday. 
                </p>
                <p class="paragraph-text">
                    The main reasons for having travel insurance if you’re travelling to Spain are for the following main benefits:
                    <ul class="main-list-class extra-padding-top">
                        <li class="list-item extra-padding-bottom"><strong>Cancellation cover</strong> (if you need to cancel before you travel)</li>
                        <li class="list-item extra-padding-bottom"><strong>Curtailment cover</strong> (if you need to cut short your trip / come home early)</li>
                        <li class="list-item extra-padding-bottom"><strong>Medical expenses cover</strong> (cover for emergency medical treatment)</li>
                        <li class="list-item extra-padding-bottom"><strong>Personal possessions cover</strong> (cover for lost, stolen or damaged belongings)</li>
                        <li class="list-item extra-padding-bottom"><strong>Personal liability cover</strong> (protection if you cause injury to another person or their property)</li> 
                    </ul>
                </p>  
                <p class="paragraph-text">
                    The above is just a snippet of what travel insurance can cover you for, for example, you may also wish to cover your gadgets or add business cover, which are both options here at Covered2go. Please see our <a href="policy-wordings-mul" target="_blank" class="text-link">Policy Wordings</a> for full details of cover.
                </p>
                <p class="blue-text">What type of travel insurance do I need for Spain?</p>
                <p class="paragraph-text">
                    A <strong>European travel insurance</strong> policy will cover your trip to Spain. However, if you plan to travel more than once this year, and that happens to be outside of Europe, then a Worldwide Multi-trip policy might be the ideal choice for you. This would cover you for an unlimited number of trips, across the globe. 
                </p>
                <p class="paragraph-text">
                    Whatever your plans are, at Covered2go, you can get a travel insurance quote in minutes and you can tailor your policy to suit your travelling requirements. 
                </p>
                <p class="paragraph-text">
                    Some of the travel insurance options available are: 
                    <ul class="main-list-class extra-padding-top">
                    <li class="list-item extra-padding-bottom"><strong>Single-trip insurance </strong>- If you only want to cover your trip to Spain, select <a href="single-trip-travel-insurance" target="_blank" class="text-link">single-trip travel insurance</a>, and choose Europe as your destination.</li>
                    <li class="list-item extra-padding-bottom"><strong>Annual multi-trip insurance </strong>- If you’re planning to take more than one holiday in the year, it might be more economical to purchase an <a href="annual-trip-travel-insurance" target="_blank" class="text-link">insurance policy for the entire year</a>. You can choose from a policy that only covers trips within Europe, or policies that will cover you across the world. </li>
                            <li class="list-item extra-padding-bottom"><strong>Longstay insurance </strong>- If you’re visiting Spain as part of a longstay or backpacking holiday, you may find that the ideal choice is a <a href="longstay-travel-insurance" target="_blank" class="text-link">longstay insurance policy</a>, which can cover you for longer trips or if you’ll be visiting several different countries</li>
                            </ul>    
                    </p>
                    <p class="blue-text">What should my Spain travel insurance include?</p>
                    <p class="paragraph-text">
                        Travel insurance for holidays to Spain should include:
                        <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Emergency Medical cover </strong>– here at Covered2go, we offer cover up to £10Million for medical expenses. <br/> If you have a <a href="ghic-information-everything-you-need-to-know" target="_blank" class="text-link">European Health Insurance Card (EHIC)</a> this continues to be valid until it expires. If you don’t have an EHIC or it has expired, then you can apply for a new Global Health Insurance Card (GHIC) – which is free and will work in a similar way. The EHIC and GHIC are useful to have but for full peace of mind it is best to get travel insurance cover on top.</li>
                            <li class="list-item extra-padding-bottom"><strong>Cancellation cover </strong>- which pays out up to a certain amount if you need to cancel your holiday or cut it short due to an unforeseen event. Our policies will cover you for cancellation up to £10,000 per person.</li>
                            <li class="list-item extra-padding-bottom"><strong>Repatriation</strong>- which covers the cost of bringing you back to the UK.</li>
                            <li class="list-item extra-padding-bottom"><strong>Personal possessions cover </strong>- which insures you in case your luggage is lost during your trip or any of your possessions are stolen. If you’re taking any particularly expensive items – such as jewellery – on your holiday, make sure they’ll be included in your insurance.</li>
                            <li class="list-item extra-padding-bottom"><strong>Gadget cover </strong>- which will help in case a possession such as a mobile phone or a GoPro is lost or stolen.</li>
                        </ul>
                    </p>
                    <p class="paragraph-text">
                        Here at Covered2go, we have three levels of cover, Silver, Gold and Platinum. Each cover level has an excess to pay per person, which you would have to pay yourself, towards any claim. Our policy excesses range from £50 to £75, and we also have the option to waive the excess for a fee, which you can choose when you get your online quote.
                    </p>
                    <p class="paragraph-text">
                        The full details of your insurance will be in the policy wording, so make sure you read it in full before you choose your travel insurance.    
                    </p>
                    <p class="blue-text">What won’t be covered in my Spanish travel insurance?</p>
                    <p class="paragraph-text">
                        There are some features that may not be included in your insurance for your trip to Spain. These are known as exclusions. Make sure know what you can and can’t claim for before you make your holiday plans. You can read our policy exclusions within our <a href="policy-wordings-mul" target="_blank" class="text-link">Policy Wordings</a>.
                        <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Sports and activities </strong>- Our policies might not cover you for accidents or injuries you sustain during dangerous sports as standard, so it is important to check that the activities you wish to take part in are covered or can add extra cover for. For more information, check out our helpful <a href="sports-activities-cover" target="_blank" class="text-link">sports and activities guide</a>. </li>
                            <li class="list-item extra-padding-bottom"><strong>Medical conditions </strong>- If you have a pre-existing medical condition, or you’ve had one in the past for which you’ve received advice or treatment, you would need to disclose any <a href="pre-existing-medical-conditions" target="_blank" class="text-link">pre-existing medical conditions</a> to us, to ensure you have full cover in place for your trip. </li>
                            <li class="list-item extra-padding-bottom"><strong>Civil unrest, war or terrorism </strong>- Spain is a stable and peaceful country, however, like most insurers, we would not cover you for incidents related to civil unrest, war or terrorism, so exercise caution. </li>
                            <li class="list-item extra-padding-bottom"><strong>Substance abuse </strong>– Like most insurers, if you suffer an accident or injury that can be linked to you drinking too much or using illegal drugs, it’s likely your claim would be refused.</li>
                        </ul>
                    </p>
                    <p class="blue-text">When should my Spain insurance policy start from?</p>
                    <p class="paragraph-text">
                        To ensure you’ll be covered in case you need to cancel prior to your holiday, make sure you’re covered from the day you book your trip, not the day you actually leave. 
                    </p>
                    <p class="blue-text">Medical Help and Dengue Fever</p>
                    <p class="paragraph-text">
                        If you need medical help while you’re in Spain, you should find that the healthcare facilities are around the same high quality that you’d expect in the UK. If medical staff aren’t able to communicate with you in English, you might be provided with an interpreter (costs around €20 per hour).
                    </p>
                    <p class="paragraph-text">
                        Dengue fever has been reported in some areas of Spain, especially around the southern coast. It’s spread by mosquitoes, but there’s currently no vaccine or treatment – so it’s best to use insect repellent to avoid mosquito bites.
                    </p>
                    <p class="paragraph-text">
                        For more information or advice on health and vaccinations for Spain head to the <a href="https://www.gov.uk/foreign-travel-advice/spain/health" target="_blank" class="text-link">FCDO website</a>.
                    </p>
                    <p class="blue-text">Travelling to Spain? Here’s some information you should take with you:</p>
                    <p class="paragraph-text">
                        <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Language</strong>– Spanish, Catalan, Galician, Basque</li>
                            <li class="list-item extra-padding-bottom"><strong>Currency</strong>- Euro</li>
                            <li class="list-item extra-padding-bottom"><strong>Dialling Code</strong>- +34</li>
                            <li class="list-item extra-padding-bottom"><strong>Plugs</strong>– plug socket type C and F – adapter needed</li>
                            <li class="list-item extra-padding-bottom"><strong>Emergency services </strong>– General – 112, Police – 091, Medical – 061</li>
                            <li class="list-item extra-padding-bottom"><strong>Embassy address </strong>- British Consulate General, Torre Espacio, Paseo de la Castellana, 259D, 28046, Madrid</li>
                            <li class="list-item extra-padding-bottom"><strong>Embassy telephone </strong>- +34 917 14 63 00</li>
                            <li class="list-item extra-padding-bottom"><strong>Spain travel advice </strong>advice – Visit the <a href="https://www.gov.uk/foreign-travel-advice/spain/health" target="_blank" class="text-link">FCDO website</a></li>
                        </ul>
                    </p>
                    <p class="blue-text">Top travel tips for Spain:</p>
                    <p class="paragraph-text">
                        Spain is full of beautiful sights and exciting things to do. Use our Spanish travel tips to help you make the most out of your holiday:
                        <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Be careful in the heat: </strong>Spain’s hot summers are a big draw but be careful in the heat. July temperatures can reach 40 degrees in some areas. Take sensible precautions to avoid heatstroke: use sunscreen, wear a hat, and keep a bottle of water handy.</li>
                            <li class="list-item extra-padding-bottom"><strong>Tipping:</strong>It isn’t mandatory to tip in Spain, but it is welcomed. If you were pleased with the service, usually a tip in the region of 10% is very much appreciated.</li>
                            <li class="list-item extra-padding-bottom"><strong>Siestas:</strong>Afternoon naps are taken very seriously in Spain. Most shops and restaurants should remain open all day in big cities and tourist resorts, however, if you’re exploring further afield, don’t be surprised if you can’t find much open from around 2pm. </li>
                            <li class="list-item extra-padding-bottom"><strong>Night owls: </strong>Nightlife in Spain doesn’t tend to get going until around 9pm. It wouldn’t be unusual for a relaxed meal out to last way past midnight and don’t be surprised if the local bars seem empty at 7pm… the night is very much, still young. </li>
                            <li class="list-item extra-padding-bottom"><strong>Eat local: </strong>Some of Spain’s most famous delicacies include, paella, chorizo, or iberico ham. The regional food in Spain is great too, and some of it is still relatively undiscovered. From the soft sobrassada sausages of Majorca to the silky black txipirones of the Basque region, there will always be something new to try wherever you’re headed.</li>
                        </ul>
                    </p> 
                    <p class="blue-text">Cheap travel insurance is not always the best way to go, if you’re travelling to Spain:</p>   
                    <p class="paragraph-text">
                        Travel insurance for Spain is essential to keep yourself, and anyone you’re travelling with protected, but not all policies are the same and cheapest is not always best. 
                    </p>
                    <p class="paragraph-text">
                        Here at Covered2go, we have a wide range of travel insurance policies, and three different cover levels, Silver, Gold and Platinum, each having differing cover limits, to help you choose the right cover for your trip. 
                    </p>
                    <p class="paragraph-text">
                        You can get a quick quote in minutes, we just need to know where you are going, how long for, a little bit about you and any additional options you need for your trip, <a href="get-quote" target="_blank" class="text-link">start your quote now!</a>
                    </p>

            </div>    
            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
            <info-boxes/>
            <!-- <trustpilot-banner/> -->
            
            <div class="content mt-4">
                <p class="mb-1"><strong>*Please note:</strong></p>
                <p class="my-1">Available on Gold, Platinum and Cruise only.</p>
                <p class="my-1">Cover is provided so long as you did not cancel your trip because of FCDO, government or local authority advice relating to any infectious disease including Covid-19.</p>
                <p class="my-1">Terms and Conditions apply. Please see our <a >Policy Wording</a> for full details. </p>
            </div>
        </div>
    </div>
    </transition>
</template>
<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'UKTravelInsurance',
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	},
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>
